<template>
	<b-modal size="sm" id="modal-quotas-housing-single" title="Cobro de cuotas" ok-only hide-footer no-close-on-backdrop ref="modalHsingle" @hide="modalWasClose">		
		<b-overlay :show="isSavingCuota " v-if="selectedFeeHousing">
			<ValidationObserver ref="modalQuotasSingle" v-slot="{ invalid }">
				<b-form @submit.prevent="handlerQuotas">
					<b-row>
					
						<b-col md="12">
							<b-form-group label="Periodicidad">
								<div class="period-h">
									<span>Año: <b-badge variant="primary"> {{ selectedFeeHousing.yearCondoFeeHousing }} </b-badge></span>
									<span>Mes: <b-badge variant="primary"> {{ month }} </b-badge> </span>									
								</div>
							</b-form-group>
						</b-col>
						
						<b-col md="12">
							<ValidationProvider rules="required" name="Monto">
								<b-form-group label="Monto" slot-scope="{ valid, errors }">
									<b-form-input
										type="text"                  					                                  
										:state="errors[0] ? false : valid ? true : null"
										v-model="feeCuota.amount"
										placeholder="0.00"
										@blur="formatAmount(feeCuota.amount)"
										@keypress="onlyNumberWithDecimal"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>				
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="required" name="Fecha de pago">
								<b-form-group label="Fecha de pago" slot-scope="{ valid, errors }">
									<b-form-input
										type="date"                  					                                  
										:state="errors[0] ? false : valid ? true : null"
										v-model="feeCuota.paymentDate"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>				
						</b-col>
					
						<b-col md="12" >
							<ValidationProvider rules="required" name="Forma de pago">
								<b-form-group label="Forma de pago" slot-scope="{ valid, errors }">								
									<b-form-select :state="errors[0] ? false : valid ? true : null" v-model="feeCuota.paymentMethod" >
											<option value="">Seleccione una opción</option>                                        
											<option v-for="pType in paymentTypes" :key="pType.id" :value="pType.id" > {{ pType.name }} </option>
										</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>					                
						</b-col>
						
						<b-col md="12" >
							<ValidationProvider rules="" name="Referencia de pago">
								<b-form-group label="Referencia de pago" slot-scope="{ valid, errors }">
									<b-form-input
										type="text"                  					                                  
										:state="errors[0] ? false : valid ? true : null"
										v-model="feeCuota.paymentReference"
									>
									</b-form-input>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</ValidationProvider>				
						</b-col>

						<b-col md="12">
							<ValidationProvider rules="" name="Notas">
								<b-form-group label="Para agregar notas">
									<b-form-textarea
										v-model="feeCuota.notes"
										placeholder="Escribe la nota"
										:maxlength="100"
										rows="2"
									>
									</b-form-textarea>
								</b-form-group>
							</ValidationProvider>
						</b-col>

					</b-row>
					
					<div class="btn-modal">
						<b-button type="submit" :disabled="invalid" variant="primary"> Guardar </b-button>
					</div>
				</b-form>
			</ValidationObserver> 		
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { numberWithCommas, showAlertMessage } from "@/helpers/helpers";
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
	mixins: [utils],
	props:{              
        paymentTypes: {
            type: Array,
            required: true,
        }
    },
	data(){
		return {
			feeCuota: {
				amount: '',				
				isMassive: 0,								
				notes: '',
				paymentDate: '',				
				paymentMethod: '',
				paymentReference: '',				
			},			
			isSavingCuota: false,
						
		}
	},
	
	computed: {
        ...mapState('fivesClubCatalogs', ['housing','selectedFeeHousing']),   
		...mapState('auth', ['user']),
		month(){
			const numberMonth = parseInt(this.selectedFeeHousing.monthCondoFeeHousing) - 1 
			const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
			return monthNames[numberMonth]
		},
		maxAmount(){
			return  Math.abs(this.selectedFeeHousing.condoFeeHousing)
		}
                                      
    },
	methods:{
		
		...mapActions('fivesClubCatalogs', ['saveCondoFeeCalculation', 'fetchCondoFeeHousingDetail']),	
        ...mapMutations('fivesClubCatalogs',['setCondominiumFeeSelected','setIsLoadingDetailsCondominiumFee','setDetailsCondominiumFee']), 		        	        
		formatAmount(amount){
			if(amount > this.maxAmount){
				this.feeCuota.amount = ''
                return showAlertMessage("Monto superior al límite", "InfoIcon", 'El monto no debe ser mayor a la Cuota condominal', "warning", 4000, "bottom-right" )				
			}
			this.feeCuota.amount = numberWithCommas(amount)
		},
		async handlerQuotas(){
			this.isSavingCuota = true
            
			const payload  = {
				...this.feeCuota,
				ids: [ this.selectedFeeHousing.id],
				isPaid: 1,
				idCondoFee: this.selectedFeeHousing.idCondoFee,
				idHousingFee: this.selectedFeeHousing.id,				
				userId: this.user.idUser,
			}	
			// console.log(payload)
			// return
			const { status, message } = await this.saveCondoFeeCalculation(payload)

            if (status) {
                showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )
                Object.assign(this.$data, this.$options.data());
                this.$refs.modalQuotasSingle.reset(); //reset form  				
            }
			
			this.isSavingCuota = false
            this.$root.$emit("bv::hide::modal", 'modal-quotas-housing-single')// cierro modal
			await this.getDetailRow(this.selectedFeeHousing) //refresh list detail
		},
		async getDetailRow(item){
            const idHousingFee = item.id
            this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: true}) // ejecuto el loader en true
            const { status, data } = await this.fetchCondoFeeHousingDetail( idHousingFee )    // pide detalle al back   
            if( status && data.length > 0 ) {    
                this.setDetailsCondominiumFee({ id: idHousingFee, details:data })
            }         
            this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: false}) // ejecuto el loader en true
        },
		modalWasClose(){			
            this.setCondominiumFeeSelected({id: this.selectedFeeHousing.id, boolean: false})   
			Object.assign(this.$data, this.$options.data());
		}
	}
}
</script>

<style scoped>
.btn-modal{
	display: flex;
	justify-content: flex-end;
}

.period-h{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-modal{
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
	background-color: #f8f8f8;
	border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
	padding: 0.8rem 1.4rem;
	border-top-left-radius: calc(0.358rem - 1px);
    border-top-right-radius: calc(0.358rem - 1px);
}
.close-modal{
	padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
    border-radius: 0.357rem;
    background: #fff;
	margin: -0.8rem -1.4rem -0.8rem auto;
	transform: translate(8px, -2px);
	opacity: 1;
    transition: all 0.23s ease 0.1s;
    position: relative;
	border: 0;
	font-size: 1rem;
}

</style>