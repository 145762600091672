var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalHsingle",attrs:{"size":"sm","id":"modal-quotas-housing-single","title":"Cobro de cuotas","ok-only":"","hide-footer":"","no-close-on-backdrop":""},on:{"hide":_vm.modalWasClose}},[(_vm.selectedFeeHousing)?_c('b-overlay',{attrs:{"show":_vm.isSavingCuota}},[_c('ValidationObserver',{ref:"modalQuotasSingle",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handlerQuotas($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Periodicidad"}},[_c('div',{staticClass:"period-h"},[_c('span',[_vm._v("Año: "),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.selectedFeeHousing.yearCondoFeeHousing)+" ")])],1),_c('span',[_vm._v("Mes: "),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.month)+" ")])],1)])])],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Monto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Monto"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"0.00"},on:{"blur":function($event){return _vm.formatAmount(_vm.feeCuota.amount)},"keypress":_vm.onlyNumberWithDecimal},model:{value:(_vm.feeCuota.amount),callback:function ($$v) {_vm.$set(_vm.feeCuota, "amount", $$v)},expression:"feeCuota.amount"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha de pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha de pago"}},[_c('b-form-input',{attrs:{"type":"date","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.feeCuota.paymentDate),callback:function ($$v) {_vm.$set(_vm.feeCuota, "paymentDate", $$v)},expression:"feeCuota.paymentDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Forma de pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Forma de pago"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.feeCuota.paymentMethod),callback:function ($$v) {_vm.$set(_vm.feeCuota, "paymentMethod", $$v)},expression:"feeCuota.paymentMethod"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.paymentTypes),function(pType){return _c('option',{key:pType.id,domProps:{"value":pType.id}},[_vm._v(" "+_vm._s(pType.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Referencia de pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Referencia de pago"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.feeCuota.paymentReference),callback:function ($$v) {_vm.$set(_vm.feeCuota, "paymentReference", $$v)},expression:"feeCuota.paymentReference"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Notas"}},[_c('b-form-group',{attrs:{"label":"Para agregar notas"}},[_c('b-form-textarea',{attrs:{"placeholder":"Escribe la nota","maxlength":100,"rows":"2"},model:{value:(_vm.feeCuota.notes),callback:function ($$v) {_vm.$set(_vm.feeCuota, "notes", $$v)},expression:"feeCuota.notes"}})],1)],1)],1)],1),_c('div',{staticClass:"btn-modal"},[_c('b-button',{attrs:{"type":"submit","disabled":invalid,"variant":"primary"}},[_vm._v(" Guardar ")])],1)],1)]}}],null,false,226957089)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }