<template>
    <div>
       
        <div class="actions-filter" >            
            <b-form-group label="Numero de registros" v-show="condominiumFees.length > 0">
                <v-select
                    v-model="porPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"                        
                    @input="changePerPage"
                />
            </b-form-group>

            <b-form-group label="Buscar en tabla" v-show="condominiumFees.length > 0" >
                <b-form-input                        
                    v-model="filter.queryHousing"
                    placeholder="Buscar..."                        
                    @input="setFilter"
                    class="input-search-t"
                />
            </b-form-group>
            
        </div>

        <b-table
            :fields="fieldsFees"
            :items="condominiumFeesData"
            class="mb-0 "
            show-empty
            empty-text="No hay registros para mostrar"
            :tbody-tr-class="rowClass"
        >
            <template #cell(monthCondoFeeHousing)="row"  >                    
                <b-link class="edit-h capitalize-text" :class="colorQuarter(row.item)" @click="toggleDetails(row.item)">
                    <span  > {{ formatDate(row.item.monthCondoFeeHousing) }}  </span>                    
                    <feather-icon :icon="!row.item.detailsShowing ? 'EyeIcon': 'EyeOffIcon' " size="16"/>                     
                </b-link>                                                           
            </template>

            <template #row-details="row">
                <b-overlay :show="row.item.isLoadingDetails" >
                    <b-table
                        :items="row.item.detailsFee"
                        :fields="fieldsDetailFee"           
                        primary-key="id"            
                        show-empty
                        empty-text="No se tiene registros en el detalle"
                        responsive  
                        no-headers                         
                        :thead-class="!row.item.detailsFee.length ? 'd-none' :''"        
                    >
                    
                        <template #cell(paymentdate)="row">                    
                            <span>{{ row.item.paymentdate ? row.item.paymentdate : 'N/A' }}</span>                   
                        </template>

                        <template #cell(paymentmethod)="row">                    
                            <span>{{ row.item.paymentmethod ? row.item.paymentdate : 'N/A' }}</span>                   
                        </template>

                    </b-table>
                </b-overlay>                
            </template>

            <template #cell(actions)="row">                            
                <b-dropdown
                    toggle-class="p-0"
                    no-caret
                    right
                >
                    <template #button-content>
                        <b-button variant="primary" size="sm">
                            <feather-icon icon="MoreHorizontalIcon" style="color: white" /> 
                        </b-button>
                    </template>

                    <b-dropdown-item @click="openModalChargeQuota(row.item)">
                        <feather-icon icon="UploadIcon" size="12" />
                        <span class="align-middle ml-50"> Cargar cuota</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="openModalFeeQuota(row.item)">
                        <feather-icon icon="DollarSignIcon" />
                        <span class="align-middle ml-50"> Cobro de cuota </span>
                    </b-dropdown-item>
                    <b-dropdown-item :href="`${authURL}/FivesClub/accountstatement/${row.item.id}/`" target="_blank" rel="noopener noreferrer" >
                        <feather-icon icon="FileTextIcon" size="12" />                        
                        <span class="align-middle ml-50">Estado de cuenta </span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

        <HousingModalQuotasSingle  :paymentTypes="paymentTypes" />
        <HousingModalQuotaCharge />


        <PaginationTable
            v-show="condominiumFees.length > 12"
            :currentPage="currentPage"
            :totalRows="condominiumFees.length"
            :perPage="porPage"
            @change-page="changePage"
        />

    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import { showAlertMessage, toCurrency, formatDateSpecific } from '@/helpers/helpers'
import PaginationTable from '@/modules/fivesClub/components/catalogs/housing/PaginationTable'
import HousingModalQuotasSingle from '@/modules/fivesClub/components/catalogs/housing/HousingModalQuotasSingle'
import HousingModalQuotaCharge from '@/modules/fivesClub/components/catalogs/housing/HousingModalQuotaCharge'


import vSelect from 'vue-select'

export default {
    props:{             
        paymentTypes: {
            type: Array,
            required: true,
        }
    },
    components: { vSelect, HousingModalQuotasSingle, HousingModalQuotaCharge, PaginationTable },

    data(){
        return {
            porPage: 12,
            // perPage: 10,
            currentPage: 1,
            perPageOptions: [1, 6, 12, 18, 36, 60, 90, 120],
            fieldsFees: [
                {
                    key:'yearCondoFeeHousing', label:'Año'
                },
                { 
                    key:'monthCondoFeeHousing', label:'Mes', class:'text-justify',
                    formatter: value => {
                        return formatDateSpecific({date: value, format:'M', toFormat:'MMMM',})
                    },
                    thStyle: { width: "200px" }
                },
                {   key:'condoFeeHousing', label:'Cuota Condominal',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }
                },               
                {   key:'balanceFee', label:'Saldo',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }                    
                },                
                
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
            fieldsDetailFee:[
                {
                    key:'paymentdate', label:'Fecha de pago'
                },
                {
                    key:'paymentmethod', label:'Forma de pago'
                },
                {
                    key:'userName', label:'Usuario'
                },
                {
                    key:'subtype', label:'Movimiento'
                },
                {
                    key:'quantity', label:'Monto'
                },
                {
                    key:'notes', label:' Notas'

                }
            ],
            feeHousingSelected: null,
            authURL: process.env.VUE_APP_IMG_SRC_API,
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['housing','filter', 'requestHousing', 'condos', 'subCondos','condominiumFees']),    

        ...mapState('fivesClubCatalogs', ['selectedHousing', 'subCondos','requestHousing']),
        ...mapState('auth', ['user']),
        ...mapState("groups", ["roomsTypes"]),
        condominiumFeesData(){
            if (this.condominiumFees?.length){
                return this.condominiumFees.slice( (this.currentPage - 1) * this.porPage, this.currentPage * this.porPage )
            }
        },
        idHousing() {
            return this.$route.params.id
        } ,
        housingRoomList(){
            return this.roomsTypes
        } 
    },
    methods:{
        ...mapActions('fivesClubCatalogs',['fetchCondoFeeHousingDetail']),
        ...mapMutations('fivesClubCatalogs',['setSelectedFeeHousing','setCondominiumFeeSelected','setIsLoadingDetailsCondominiumFee', 'setDetailsCondominiumFee']), 
        formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code)
        },
        formatDate(data){
            return formatDateSpecific({date: data, format:'M', toFormat:'MMMM'})
        },
        changePerPage( perPage ){            
            this.perPage = perPage
        },
        changePage(page){
            this.currentPage = page
        },
        setFilter(e){
            console.log(e)
        },
        colorQuarter(item){
            const colors = { 1: 'quarter-one',2 : 'quarter-second', 3 : 'quarter-third' }
            return colors[item.quarter]
        },
        openModalFeeQuota(row){
            this.setSelectedFeeHousing(row)                 
            this.setCondominiumFeeSelected({id: row.id, boolean: true})       
            this.$root.$emit("bv::show::modal", 'modal-quotas-housing-single');                                        
        },
        openModalChargeQuota(row){           
            this.setSelectedFeeHousing(row)                 
            this.setCondominiumFeeSelected({id: row.id, boolean: true})       
            this.$root.$emit("bv::show::modal", 'modal-quotas-housing-charge');  
        },
        async toggleDetails(item){

            this.setCondominiumFeeSelected({id: item.id, boolean: !item._showDetails}) 

            this.condominiumFees.forEach( condFee => {
                if (condFee.id !== item.id && condFee._showDetails) {
                    this.$set(condFee, "_showDetails", !condFee._showDetails);
                }
            })

            this.$set(item, "_showDetails", !item._showDetails) // abro el colapse details 
            
            //si el row no tiene detailsFee y el collapse se abrió, se lo pido al back         
            if( !item.detailsFee.length && item._showDetails){
                const idHousingFee = item.id
                this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: true}) // ejecuto el loader en true
                const { status, data } = await this.fetchCondoFeeHousingDetail( idHousingFee )    // pide detalle al back   
                if( status && data.length > 0 ) {    
                    this.setDetailsCondominiumFee({ id: idHousingFee, details:data })
                }         
                this.setIsLoadingDetailsCondominiumFee({id: idHousingFee, boolean: false}) // ejecuto el loader en true
            }

        },
     
        rowClass(item, type) {
            const colorClass = 'table-success'
            if (!item || type !== 'row') { return }           
            if (item.isSelectedItem) { return colorClass }
        },
    }
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.capitalize-text{
  text-transform: capitalize;
}
.actions-filter{        
    display: flex;
    flex-direction: row;        
    /* justify-content: space-between;  */
    gap: 1.5rem;
}
.input-search-t{
    min-width: 240px;
    width: 350px;
}
.edit-h{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.quarter-one{
    color: #14142B ;
}

.quarter-second{
    color: #610BEF;
}

.quarter-third{
    color: #005BD4;
}

</style>