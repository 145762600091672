<template>
   <ValidationObserver ref="updateHousing" v-slot="{ invalid }"> 
    <!-- <pre>{{ housingData }}</pre> -->
        <b-form @submit.prevent="update">
            <b-row>
            <b-col md="3">
                <ValidationProvider rules="required" name="nombre">
                <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                    <b-form-input
                        class="form-control"
                        type="text"
                        v-model="housingData.name"
                        :state="errors[0] ? false : valid ? true : housingData.name === '' ? true : false"

                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <ValidationProvider rules="required" name="numero de vienda">
                <b-form-group
                    label="Numero de Vivienda"
                    slot-scope="{ valid, errors }"
                >
                    <b-form-input
                        class="form-control"
                        type="number"
                        v-model="housingData.numberHousing"
                        :state="errors[0] ? false : valid ? true : housingData.numberHousing === '' ? true : false"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <ValidationProvider rules="required" name="precio">
                <b-form-group label="Precio" slot-scope="{ valid, errors }">
                    <b-form-input
                        class="form-control"
                        type="number"
                        step="any"
                        v-model="housingData.price"
                        :state="errors[0] ? false : valid ? true : housingData.price === '' ? true : false"
                        
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <ValidationProvider rules="" name="puntos">
                <b-form-group label="Puntos" slot-scope="{ valid, errors }">
                    <b-form-input
                        class="form-control"
                        type="number"
                        v-model="housingData.points"
                        :state="errors[0] ? false : valid ? true : housingData.points === '' ? true : false"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <ValidationProvider rules="" name="porcentaje">
                <b-form-group label="Porcentaje" slot-scope="{ valid, errors }">
                    <b-form-input
                        class="form-control"
                        type="number"
                        step="any"
                        v-model="housingData.percentage"
                        :state="errors[0] ? false : valid ? true : housingData.percentage === '' ? true : false"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
               
                <ValidationProvider rules="required" name="numero de cuartos">
                <b-form-group
                    label="Numero de Cuartos"
                    slot-scope="{ valid, errors }"
                >
                    <b-form-input
                        class="form-control"
                        type="number"
                        v-model="housingData.rooms"
                        :state="errors[0] ? false : valid ? true : housingData.rooms === '' ? true : false"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <span>Sub condominio</span>
                <ValidationProvider name="sub condominio" rules="required">
                <b-form-group slot-scope="{ valid, errors }">
                    <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="housingData.subCondos"
                        @change="setHousingRooms"
                    >
                    <option value="">Seleccione sub condominio</option>
                    <option
                        v-for="selectSubCondos in subCondos"
                        :key="selectSubCondos.id"
                        :value="selectSubCondos.id"
                    >
                        {{ selectSubCondos.name }}
                    </option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="3">
                <ValidationProvider name="Catálogos de habitación" rules="required">
                <b-form-group label="Catálogos de habitación" slot-scope="{ valid, errors }">
                    <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="housingData.categoryroomsid"
                    >
                    <option value="">Seleccione habitación</option>
                    <option
                        v-for="selectHousingRooms in roomsTypes"
                        :key="selectHousingRooms.id"
                        :value="selectHousingRooms.id"
                    >
                        {{ selectHousingRooms.name }}
                    </option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="6">
                <div class="float-left pt-2 mt-1">
                <b-form-checkbox
                    v-model="housingData.status"
                    :value="true"
                    class="custom-control-success"
                >
                    Activado
                </b-form-checkbox>
                </div>
            </b-col>
            </b-row>
            <b-row>
            <b-col>
                <div class="float-right">
                <b-button
                    type="submit"
                    class="btn-block"
                    variant="primary"
                    :disabled="invalid || isUpdatingHousing || isLoadingData"
                >
                        Actualizar
                </b-button>
                </div>
            </b-col>
            </b-row>
        </b-form>            
	</ValidationObserver>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
export default {
    props:{
        isLoadingData: {
            type: Boolean,
            required: true,
        },
        isUpdatingHousing: {
            type: Boolean,
            required: true,
        },
        housingData: {
            type: Object,
            required: true,
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['selectedHousing', 'subCondos','requestHousing']),
        ...mapState('auth', ['user']),
        ...mapState("groups", ["roomsTypes"]),
             
    },
    methods:{
        ...mapActions('groups', ['fetchRoomsTypes']),
        update(){
            this.$emit('update-housing');
        },
        async setHousingRooms(){        
            const subcondo = this.subCondos.find(subcondo=> subcondo.id === this.housingData.subCondos)      
            if (subcondo){
                await this.fetchRoomsTypes(subcondo.resorts)
            }
        },
    }
}
</script>